<template>
  <div>
    <provider-comp :type="type"></provider-comp>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import ProviderComp from "@/components/admin/provider/ProviderComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
  name: "edit-provider",
  components: {ProviderComp},

  setup() {

    const type = ref('edit');
    onMounted(() => {
      setCurrentPageBreadcrumbs("Update Provider", ["Providers"] , checkPermission('providers.store') ? 'create-provider' : null);
    });


    return {
      getIllustrationsPath,
      type,
    };
  },

});
</script>
